import { Flex } from '@mantine/core'

import { LocalImage } from '~/components/shared/image/LocalImage'

import forbes from 'public/images/press/Forbes.png'
import globeAndMail from 'public/images/press/Globe_and_mail.png'
import phocuswire from 'public/images/press/Phocuswire.png'
import skift from 'public/images/press/Skift.png'
import techcrunch from 'public/images/press/Techcrunch.png'

const images = [forbes, globeAndMail, skift, techcrunch, phocuswire]

export const PressMentions = ({ isMobileScreen }: { isMobileScreen: boolean }) => {
  return (
    <Flex
      py={{ base: 32, sm: 24 }}
      direction="row"
      align={{ base: 'center', sm: 'center' }}
      justify={isMobileScreen ? 'center' : 'space-between'}
      bg="appWhite.0"
      gap={{ base: 18, sm: 48 }}
      sx={{
        borderTop: '1px solid rgba(0, 0, 0, 0.10)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.10)',
      }}
      wrap="wrap"
    >
      {images.map((img, idx) => (
        <LocalImage
          src={img}
          key={idx}
          height={isMobileScreen ? 48 : 64}
        />
      ))}
    </Flex>
  )
}

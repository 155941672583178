import { CSSObject, MantineTheme, TitleProps, Title as MatineTitle } from '@mantine/core'
import React from 'react'

import { mobileTypography, typography, commonItalicStyles } from '~/theme/typography'

export type Variant = keyof typeof typography

interface TypographyProps extends Omit<TitleProps, 'variant'> {
    variant?: Variant
    id?: string
    mobileVariant?: Variant
    mobileVaraintSource?: 'desktop' | 'mobile'
}

export const Title: React.FC<TypographyProps> = ({
    variant,
    children,
    sx,
    mobileVariant,
    mobileVaraintSource,
    ...rest
}) => {
    if (!variant) {
        variant = `h${rest['order']}` as Variant
    }
    const desktopStyle: CSSObject = typography[variant]
    const smallerScreenStyle: CSSObject = mobileVariant
        ? mobileVaraintSource == 'desktop'
            ? typography[mobileVariant]
            : mobileTypography[mobileVariant]
        : mobileTypography[variant]
    const allStyles = (theme: MantineTheme) => {
        return {
            ...sx, // only works for standard sx object and not theme functions
            [theme.fn.largerThan('sm')]: { ...desktopStyle },
            [theme.fn.smallerThan('sm')]: { ...smallerScreenStyle },
        }
    }
    return (
        <MatineTitle
            sx={allStyles}
            {...rest}
        >
            {children}
        </MatineTitle>
    )
}


interface TitleItalicProps {
    children: React.ReactNode;
}

export const TitleItalic: React.FC<TitleItalicProps> = ({ children }) => {
    return (
        <i style={{...commonItalicStyles}}>
            {children}
        </i>
    )
}
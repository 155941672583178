import { notify } from '~/components/shared/notify'
import { duplicateBoard } from '~/endpoints/board'
import { BoardSummaryType } from '~/endpoints/model'

export const useCreateDuplicate = (onDuplicateComplete?: (newBoard: BoardSummaryType) => void) => {
  const duplicateGuide = async (board: BoardSummaryType) => {
    try {
      const clonedBoard = await duplicateBoard(board.token)
      notify(false, `✨ Guide duplicated and named '${clonedBoard.name}'`)
      onDuplicateComplete?.(clonedBoard)
    } catch (e) {
      notify(true, e.message)
    }
  }

  return { duplicateGuide }
}

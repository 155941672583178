import { Box, Flex } from '@mantine/core'

import { SearchInput } from '~/components/home/SearchInput'
import { Typography } from '~/components/shared/text/Typography'

import { LocalImage } from '../shared/image/LocalImage'

import heroImg from 'public/images/home/thatch-hero-image.png'

// const Title = ({ value, italic }: { value: string; italic?: boolean }) => (
//   <Typography
//     sx={{ letterSpacing: '-0.348px', whiteSpace: 'nowrap' }}
//     variant={italic ? 'h1Italic' : 'h1'}
//   >
//     {value}
//   </Typography>
// )

import { Title, TitleItalic } from '~/components/shared/text/Title'

const BulletPoint = ({ text, isMobileScreen }: { text: string; isMobileScreen: boolean }) => (
  <Typography
    sx={{
      display: 'flex',
      flexDirection: 'row',
      gap: 4,
      letterSpacing: '-0.088px',
      lineHeight: '120% !important',
    }}
    variant="body1"
  >
    <Typography
      color="appBlack.0"
      sx={{ letterSpacing: '-0.088px' }}
      variant="body1"
    >
      ✓
    </Typography>
    {text}
  </Typography>
)

const HeroSection = ({
  searchInputRef,
  isMobileScreen,
}: {
  searchInputRef: any
  isMobileScreen: boolean
}) => {
  return (
    <Flex
      justify="space-between"
      direction={isMobileScreen ? 'column' : 'row'}
      mb={isMobileScreen ? 0 : 40}
      gap={isMobileScreen ? 56 : undefined}
      w="100%"
    >
      <Flex
        direction="column"
        justify="center"
        gap={isMobileScreen ? 32 : 48}
      >
        {/* <Flex gap={isMobileScreen ? 8 : 10}>
          <Title value="Great trips" />
          <Title
            value="start here"
            italic
          />
        </Flex> */}
        <Title variant='h1' order={1}>Great trips <TitleItalic>start here</TitleItalic></Title>

        <Flex
          direction="column"
          gap={16}
          maw={511}
        >
          <Typography
            variant="body1"
            sx={{ letterSpacing: '-0.088px', textAlign: isMobileScreen ? 'center' : undefined }}
            maw={450}
          >
            Discover unique travel guides and planning services from local experts in 80+ countries.
          </Typography>

          <Flex
            direction="column"
            sx={isMobileScreen ? { alignItems: 'center', justifyContent: 'center' } : {}}
          >
            <BulletPoint
              text="Reduce planning time and stress"
              isMobileScreen={isMobileScreen}
            />
            <BulletPoint
              text="Tap into authentic hidden gems"
              isMobileScreen={isMobileScreen}
            />
            <BulletPoint
              text="Take trips that are true to you"
              isMobileScreen={isMobileScreen}
            />
          </Flex>
        </Flex>

        <Box
          maw={511}
          mt={isMobileScreen ? 24 : 0}
        >
          <SearchInput customRef={searchInputRef} />
        </Box>
      </Flex>

      <Box ml={isMobileScreen ? -16 : 0}>
        <LocalImage
          src={heroImg}
          width={isMobileScreen ? window.innerWidth : 511}
          priority
          quality={100}
          alt="hero"
        />
      </Box>
    </Flex>
  )
}

export default HeroSection

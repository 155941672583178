import { useState } from 'react'

import { UnderlineLink } from '~/components/shared/UnderlineLink'
import { SvgIcon, SvgIconType } from '~/components/shared/image/SvgIcon'
import { notify } from '~/components/shared/notify'
import { saveBoard, unSaveBoard } from '~/endpoints/board'
import { BoardSummaryType } from '~/endpoints/model'

export const useToggleSaveUnSave = (
  onSaveUnSaveComplete: (idx: number, isSaved: boolean) => void
) => {
  const [isSaving, setIsSaving] = useState(false)
  const toggleSaveUnSave = async (board: BoardSummaryType, idx: number) => {
    const {
      name,
      token,
      state: { saved },
    } = board
    setIsSaving(true)
    try {
      if (saved) {
        await unSaveBoard(token)
        notify(
          false,
          <>
            {name} have been unsaved from{' '}
            <UnderlineLink
              href="/guides"
              label="Your Library"
            />
          </>,
          'Guide unsaved from Your Library',
          <SvgIcon
            type={SvgIconType.SAVE_OUTLINE}
            fill="appWhite.0"
            width={24}
            height={24}
          />
        )
      } else {
        await saveBoard(token)
        notify(
          false,
          <>
            {name} have been saved to{' '}
            <UnderlineLink
              href="/guides"
              label="Your Library"
            />
          </>,
          'Guide saved to Your Library',
          <SvgIcon
            type={SvgIconType.SAVE_FILLED}
            fill="appWhite.0"
            width={24}
            height={24}
          />
        )
      }
      onSaveUnSaveComplete(idx, !saved)
    } catch (e) {
      notify(true, e.message)
    } finally {
      setIsSaving(false)
    }
  }

  return { toggleSaveUnSave, isSaving }
}

import { createContext, useContext } from 'react'

export type BoardSummaryStateContextType = {
  sourceLocation: string
}

const BoardSummaryStateContext = createContext<BoardSummaryStateContextType | undefined>(undefined)

type Props = {
  children: React.ReactNode
  sourceLocation: string
}
export const BoardSummaryStateProvider = ({ children, sourceLocation }: Props) => {
  return (
    <BoardSummaryStateContext.Provider
      value={{
        sourceLocation,
      }}
    >
      {children}
    </BoardSummaryStateContext.Provider>
  )
}

export const useBoardSummaryState = () => {
  const result = useContext(BoardSummaryStateContext)
  if (!result) {
    throw new Error('Context used outside of its Provider!')
  }
  return result
}

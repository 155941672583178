import { Flex } from '@mantine/core'

import { Typography } from '~/components/shared/text/Typography'

import { ThatchLink } from '../shared/ThatchLink'

type ActionCardProps = {
  title: {
    normal?: string
    italic?: string
  }
  subtitle: string
  color: string
  redirectUrl?: string
  btnLabel?: string
  onClick?: () => void
}
const ActionCard = ({
  title,
  subtitle,
  color,
  redirectUrl,
  onClick,
  btnLabel = 'Learn more →',
}: ActionCardProps) => {
  const Card = () => (
    <Flex
      onClick={onClick}
      maw={352}
      h="100%"
      p={32}
      gap={24}
      direction="column"
      bg={color}
      sx={{ flexGrow: 1, borderRadius: 8, cursor: 'pointer' }}
    >
      <Flex direction="column">
        <Typography
          variant="titleSerif"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {title.normal}
        </Typography>
        {title.italic && (
          <Typography
            variant="titleSerifItalic"
            sx={{ whiteSpace: 'nowrap' }}
          >
            {title.italic}
          </Typography>
        )}
      </Flex>

      <Typography
        variant="body3"
        sx={{ letterSpacing: '-0.072px' }}
        mb={24}
      >
        {subtitle}
      </Typography>

      <Typography
        variant="button_medium"
        sx={{ cursor: 'pointer' }}
      >
        {btnLabel}
      </Typography>
    </Flex>
  )

  if (redirectUrl) {
    return (
      <ThatchLink
        style={{ justifySelf: 'end' }}
        to={redirectUrl}
        isOpenInNewWindow
        underline={false}
      >
        <Card />
      </ThatchLink>
    )
  } else {
    return <Card />
  }
}

export default ActionCard

import { IconTrash } from '@tabler/icons'

import { notify } from '~/components/shared/notify'
import { deleteBoard } from '~/endpoints/board'
import { BoardSummaryType } from '~/endpoints/model'

export const useDelete = (onDeleteComplete?: (idx: number) => void) => {
  const deleteGuide = async (board: BoardSummaryType, idx?: number) => {
    try {
      await deleteBoard(board.token)
      notify(
        true,
        `${board.name} has been successfully deleted`,
        'Guide Deleted',
        <IconTrash size={24} />
      )
      onDeleteComplete?.(idx ?? -1)
    } catch (e) {
      notify(true, e.message)
    }
  }

  return { deleteGuide }
}

import { Box, Flex, Space } from '@mantine/core'
import { useRef } from 'react'

import { BoardSummaryType, ProfileSummaryType } from '~/endpoints/model'

import { GuidesContent } from '../explore/GuidesContent'
import { SellersContent } from '../explore/SellersContent'
import StickySideLayout from '../shared/layouts/StickySideLayout'
import FeaturedContentFilters from './FeaturedContentFilters'
import { Title, TitleItalic } from '~/components/shared/text/Title'

export type FeaturedContentProps = {
  guides: BoardSummaryType[]
  sellers: ProfileSummaryType[]
  isMobileScreen: boolean
}

const FeaturedContent = ({ sellers, guides, isMobileScreen }: FeaturedContentProps) => {
  const stickySectionRef = useRef<HTMLDivElement>(null)
  return (
    <Flex>
      <StickySideLayout
        stickyContent={
          <Box ref={stickySectionRef}>
            <FeaturedContentFilters />
          </Box>
        }
        stickyContentWrapperRef={stickySectionRef}
        scrollableContent={
          <Flex
            direction="column"
            gap={32}
            mb={isMobileScreen ? 32 : undefined}
          >
            <Flex
              direction="column"
              gap={8}
            >
              <Title order={3}>
                Featured <TitleItalic>travel creators</TitleItalic>
              </Title>
              <SellersContent
                data={sellers}
                wrapperStyles={{
                  padding: 0,
                  margin: 0,
                  minWidth: isMobileScreen ? undefined : 689.5,
                }}
                onFetchData={() => { }}
                isLoading={false}
                hasMore={false}
                emptyState={null}
                showAllSellersBtn
              />
            </Flex>
            <Space h={isMobileScreen ? 0 : 16} />

            <Flex
              direction="column"
              gap={8}
            >
              <Title order={3}>
                Featured <TitleItalic>{"guides & itineraries"}</TitleItalic>
              </Title>
              <GuidesContent
                wrapperStyles={{ padding: 0, margin: 0 }}
                data={guides}
                emptyState={null}
                isLoading={false}
                onFetchData={() => { }}
                hasMore={false}
                showAllGuidesBtn
              />
            </Flex>
            <Space h={isMobileScreen ? 8 : 32} />
          </Flex>
        }
      />
    </Flex>
  )
}

export default FeaturedContent

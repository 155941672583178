import { Divider, Flex } from '@mantine/core'
import { ThatchLink } from '~/components/shared/ThatchLink'
import { useScreenSize } from '~/hooks'
import { popularCities, popularCountries } from '~/utils/constants'
import { Typography } from '~/components/shared/text/Typography'
import TagsList from '../shared/TagsSection'
import ThatchBanner from '../shared/ThatchBanner'

const FeaturedContentFilters = () => {
  const { isDesktopScreen, isMobileScreen } = useScreenSize()

  return (
    <Flex
      direction="column"
      maw={isDesktopScreen ? 302 : undefined}
    >
      <TagsList
        options={popularCities}
        containerSx={{ width: isMobileScreen ? '100%' : 302 }}
        title="Popular Destinations"
      />
      <ThatchLink style={{ justifySelf: 'end' }} to={'/geo'} underline={false} ml={8}>
        <Typography
          color='appPlaceholder.0'
          variant="button_small"
          sx={{ cursor: 'pointer', fontSize: '18px !important' }}
          pt={32}
          className='group'
        >
          All Destinations <span className="ml-0 group-hover:ml-2 transition-all">→</span>
        </Typography>
      </ThatchLink>
      <Divider
        color="rgba(0, 0, 0, 0.10)"
        my={40}
      />
      <TagsList
        options={popularCountries}
        containerSx={{ width: isMobileScreen ? '100%' : 302 }}
        title="Popular COUNTRIES"
      />
      <ThatchLink style={{ justifySelf: 'end' }} to={'/geo'} underline={false} ml={8}>
        <Typography
          color='appPlaceholder.0'
          variant="button_small"
          sx={{ cursor: 'pointer', fontSize: '18px !important' }}
          pt={32}
          className='group'
        >
          All Countries <span className="ml-0 group-hover:ml-2 transition-all">→</span>
        </Typography>
      </ThatchLink>
      <Divider
        color="rgba(0, 0, 0, 0.10)"
        my={40}
      />
      <ThatchBanner />
    </Flex>
  )
}

export default FeaturedContentFilters

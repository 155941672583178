import { notify } from '~/components/shared/notify'
import { favBoard, unFavBoard } from '~/endpoints/board'
import { BoardSummaryType } from '~/endpoints/model'

export const useToggleFavUnFav = (onFavUnFavComplete: (idx: number, isFav: boolean) => void) => {
  const toggleFavUnFav = async (board: BoardSummaryType, idx: number) => {
    const {
      name,
      token,
      state: { pinned },
    } = board
    try {
      if (pinned) {
        await unFavBoard(token)
        notify(false, `${name} removed from Favorites`)
      } else {
        await favBoard(token)
        notify(false, `${name} added to Favorites`)
      }
      onFavUnFavComplete(idx, !pinned)
    } catch (e) {
      notify(true, e.message)
    }
  }

  return { toggleFavUnFav }
}

import { Box, Divider, Flex, Space } from '@mantine/core'
import { GetStaticProps } from 'next'
import React, { ReactElement, useRef } from 'react'

import FeaturedContent from '~/components/home/FeaturedContent'
import HeroSection from '~/components/home/HeroSection'
import IntroCardsSection from '~/components/home/IntroCardsSection'
import { PressMentions } from '~/components/home/PressMentions'
import { Layout } from '~/components/layout/Layout'
import ThatchGPTButton from '~/components/shared/ThatchGPTButton'
import { BoardSummaryStateProvider } from '~/context/BoardSummaryStateProvider'
import { fetchFeaturedContent } from '~/endpoints/board'
import {
  BoardSummaryType,
  ProfileSummaryType,
  emptyAllSavedBoardByUser,
  emptyPeopleSearchdData,
} from '~/endpoints/model'
import { fetchFeaturedSellers } from '~/endpoints/user'
import { useScreenSize } from '~/hooks'
import { MAX_SCREEN_WIDTH } from '~/utils/constants'

export type HomePageProps = {
  guides: BoardSummaryType[]
  sellers: ProfileSummaryType[]
}

const Home = (props: HomePageProps) => {
  const searchInputRef = useRef<HTMLDivElement>(null)
  const { isMobileScreen } = useScreenSize()

  const focusSearchInput = () => {
    if (searchInputRef.current) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      setTimeout(() => searchInputRef.current?.focus(), 500)
    }
  }

  return (
    <>
      <BoardSummaryStateProvider sourceLocation="homepage">
        <Flex
          p={isMobileScreen ? '56px 16px' : '32px 88px 0px 88px'}
          maw={MAX_SCREEN_WIDTH}
          mx="auto"
        >
          <HeroSection
            isMobileScreen={isMobileScreen}
            searchInputRef={searchInputRef}
          />
        </Flex>

        <Box
          maw={MAX_SCREEN_WIDTH}
          mx="auto"
          p={isMobileScreen ? '0px 16px 0px 16px' : '32px 88px 0px 88px'}
        >
          <PressMentions isMobileScreen={isMobileScreen} />

          <Space h={isMobileScreen ? 48 : 72} />

          <IntroCardsSection focusSearchInput={focusSearchInput} />

          <Space h={isMobileScreen ? 48 : 72} />

          <Divider
            color="rgba(0,0,0,0.2)"
            mb={isMobileScreen ? 48 : 72}
          />

          <FeaturedContent
            isMobileScreen={isMobileScreen}
            guides={props.guides}
            sellers={props.sellers}
          />
        </Box>
      </BoardSummaryStateProvider>
      <ThatchGPTButton />
    </>
  )
}

Home.getLayout = function getLayout(page: ReactElement) {
  return <Layout hideFooter>{page}</Layout>
}

export const getStaticProps: GetStaticProps<HomePageProps> = async _context => {
  const featuredContent = await fetchFeaturedContent().catch(_ => {
    // captureSentryException(`Error getting featured guides content`)
    console.error(`Error getting featured guides content`)
    return emptyAllSavedBoardByUser
  })

  const featuredSellersContent = await fetchFeaturedSellers().catch(_ => {
    // captureSentryException(`Error getting featured sellers content`)
    console.error(`Error getting featured sellers content`)
    return emptyPeopleSearchdData
  })

  return {
    props: {
      guides: featuredContent.content || [],
      sellers: featuredSellersContent.content || [],
    },
    revalidate: 12 * 60 * 60, // 12 hours in seconds
  }
}

export default Home

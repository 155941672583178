import { Flex } from '@mantine/core'

import { useScreenSize } from '~/hooks'

import ActionCard from './ActionCard'

const IntroCardsSection = ({ focusSearchInput }: { focusSearchInput: any }) => {
  const { isMobileScreen } = useScreenSize()

  return (
    <Flex
      gap={24}
      direction={isMobileScreen ? 'column' : 'row'}
    >
      <ActionCard
        color="appBlue.0"
        redirectUrl="/services/home"
        title={{ normal: 'Get personalized', italic: 'planning services' }}
        subtitle="Connect with a local expert to ask questions, get personalized recs or have your whole trip planned for you."
        btnLabel="Find an expert →"
      />
      <ActionCard
        color="appYellow.0"
        title={{ normal: 'Browse pre-made', italic: 'guides & itineraries' }}
        subtitle="Browse guides and itineraries from local experts. Use as-is or mix with your own research to DIY your ideal trip."
        btnLabel="Browse guides →"
        onClick={focusSearchInput}
      />
      <ActionCard
        color="appLavender.0"
        redirectUrl="/thatchgpt"
        title={{ normal: 'Brainstorm your trip', italic: 'with ThatchGPT' }}
        subtitle="Not sure where you want to go yet? Chat about your trip with Travel Assistant and get personalized support."
        btnLabel="Try ThatchGPT →"
      />
    </Flex>
  )
}

export default IntroCardsSection

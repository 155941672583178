import { Box, Flex, Group } from '@mantine/core'
import { FC } from 'react'

import { ThatchLink } from '~/components/shared/ThatchLink'
import { useCurrentAuthState } from '~/context'
import { useScreenSize } from '~/hooks'

import { ThatchButton } from './ThatchButton'
import { Typography } from './text/Typography'

interface ThatchBannerProps {
  bannerTitle?: { text: string; italic?: boolean }[]
  bannerTitle2?: { text: string; italic?: boolean }[]
  btnLabel?: string
  btnLink?: string
  bannerDescription?: string
  openSignupModal?: () => void
  bg?: string
}
const ThatchBanner: FC<ThatchBannerProps> = ({
  bannerTitle = [{ text: 'Selling', italic: true }, { text: 'on Thatch' }],
  bannerTitle2,
  btnLabel = 'Learn more',
  btnLink = '/seller',
  bannerDescription = 'Thatch makes it easy for thousands of travel creators in 80+ countries to organize & monetize their travel knowledge.',
  openSignupModal,
  bg='appIce.0'
}) => {
  const { email } = useCurrentAuthState()
  const { isDesktopScreen } = useScreenSize()

  return (
    <Flex
      p="32px 16px"
      bg={bg}
      direction="column"
      sx={{ borderRadius: 8 }}
    >
      <Box>
        <Group spacing={6}>
          {bannerTitle.map((title, idx) => (
            <Typography
              key={`title-${idx}`}
              variant={
                title.italic
                  ? isDesktopScreen
                    ? 'titleSerifItalic'
                    : 'h3Italic'
                  : isDesktopScreen
                  ? 'titleSerif'
                  : 'h3'
              }
              sx={{ fontSize: isDesktopScreen ? undefined : '26px !important' }}
            >
              {title.text}
            </Typography>
          ))}
        </Group>

        {bannerTitle2 && (
          <Group spacing={6}>
            {bannerTitle2.map((title, idx) => (
              <Typography
                key={`title-${idx}`}
                variant={
                  title.italic
                    ? isDesktopScreen
                      ? 'titleSerifItalic'
                      : 'h3Italic'
                    : isDesktopScreen
                    ? 'titleSerif'
                    : 'h3'
                }
                sx={{ fontSize: isDesktopScreen ? undefined : '26px !important' }}
              >
                {title.text}
              </Typography>
            ))}
          </Group>
        )}
      </Box>

      <Typography
        mt={24}
        mb={32}
        variant="body2"
        sx={{
          fontSize: isDesktopScreen ? undefined : '20px !important',
          letterSpacing: '-0.08px',
        }}
      >
        {bannerDescription}
      </Typography>

      {email || !openSignupModal ? (
        <ThatchButton
          component={ThatchLink}
          to={btnLink}
          underline={false}
          label={<Typography variant="button_medium">{btnLabel}</Typography>}
          sx={{ width: 'min-content', paddingLeft: 22, paddingRight: 22 }}
        />
      ) : (
        <ThatchButton
          onClick={() => openSignupModal?.()}
          label={<Typography variant="button_medium">{btnLabel}</Typography>}
          sx={{ width: 'min-content', paddingLeft: 22, paddingRight: 22 }}
        />
      )}
    </Flex>
  )
}

export default ThatchBanner
